<template>
  <el-dialog
    v-model="show"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-match-rule-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-match-rule-dialog-header__title">
        {{ props.model === 'add' ? t('button.addRule') : t('button.editRule') }}
      </h4>
    </template>
    <el-scrollbar class="elv-reconciliation-task-automation-dialog-content" max-height="calc(100vh - 340px)">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-position="top">
        <el-form-item :label="t('common.ruleName')" prop="name">
          <el-input v-model="ruleForm.name" :placeholder="t('message.pleaseInput')" class="elv-rule-form-name" />
        </el-form-item>

        <el-form-item :label="t('common.conditions')" class="elv-match-rule-form-item-line condition">
          <div v-if="show" class="elv-rule-form-condition">
            <div class="elv-rule-form-condition-header">
              <div class="elv-rule-form-condition-header-title">
                <div>{{ currentRuleTypeShowInfo('LEFT').title }}:</div>
                <div>{{ t('valuation.tolerance') }}</div>
                <div>{{ currentRuleTypeShowInfo('RIGHT').title }}:</div>
                <div></div>
              </div>
              <div class="elv-rule-form-condition-header-placeholder">
                <div>{{ currentRuleTypeShowInfo('LEFT').subTitle }}</div>
                <div></div>
                <div>{{ currentRuleTypeShowInfo('RIGHT').subTitle }}</div>
                <div></div>
              </div>
            </div>
            <template v-if="conditionList.length">
              <div v-for="item in conditionList" :key="item.index" class="elv-rule-form-condition-item">
                <component
                  :is="currentRuleTypeShowInfo('LEFT').component"
                  v-if="currentRuleTypeShowInfo('LEFT').component"
                  v-model:sideDataField="item.sideAField"
                  v-model:additionalItemPath="item.additionalItemPath"
                  :conditionIndex="item.index"
                  :conditionList="conditionList"
                  :chartOfAccount="props.chartOfAccount"
                  :entityAccountAuxiliaryTypeId="props.entityAccountAuxiliaryTypeId"
                  positionType="LEFT"
                  @onRuleTypeSelectChange="onRuleTypeSelectChange"
                ></component>
                <div class="elv-rule-form-condition-item-tolerance">
                  <SingleChoiceSelect
                    v-model="item.type"
                    placeholder="Select ..."
                    width="304px"
                    :disabled="isDisabledChoice(item)"
                    :options="toleranceOptions"
                  />
                  <template
                    v-if="
                      (['DATETIME', 'AMOUNT'].includes(item.sideAField) ||
                        ['DATETIME', 'AMOUNT'].includes(item.sideBField)) &&
                      item.type === 'TOLERANCE'
                    "
                  >
                    <div class="elv-rule-form-condition-item-tolerance__item">
                      <el-select :model-value="true">
                        <el-option
                          :label="item.sideAField === 'DATETIME' ? 'Lower limit (days)' : 'Lower limit'"
                          :value="true"
                        />
                      </el-select>
                      <el-input-number v-model="item.lowerLimit" placeholder=" " :controls="false" :min="0" />
                    </div>
                    <div class="elv-rule-form-condition-item-tolerance__item">
                      <el-select :model-value="true">
                        <el-option
                          :label="item.sideAField === 'DATETIME' ? 'Upper limit (days)' : 'Upper limit'"
                          :value="true"
                        />
                      </el-select>
                      <el-input-number v-model="item.upperLimit" placeholder=" " :controls="false" :min="0" />
                    </div>
                  </template>
                </div>
                <component
                  :is="currentRuleTypeShowInfo('RIGHT').component"
                  v-if="currentRuleTypeShowInfo('RIGHT').component"
                  v-model:sideDataField="item.sideBField"
                  v-model:additionalItemPath="item.additionalItemPath"
                  :conditionIndex="item.index"
                  :conditionList="conditionList"
                  :chartOfAccount="props.chartOfAccount"
                  :entityAccountAuxiliaryTypeId="props.entityAccountAuxiliaryTypeId"
                  positionType="RIGHT"
                  @onRuleTypeSelectChange="onRuleTypeSelectChange"
                ></component>
                <div class="elv-rule-form-condition-item-delete">
                  <SvgIcon
                    name="sources-delete"
                    width="16"
                    height="16"
                    :class="{
                      'is-disabled': item.index === '0' && (item.sideBField === '' || item.sideAField === '')
                    }"
                    @click="deleteCondition(item.index)"
                  />
                </div>
              </div>
            </template>
          </div>
        </el-form-item>

        <div class="elv-reconciliation-match-rule-add-condition" @click="addCondition">
          <SvgIcon name="add-reports" width="16" height="16" />
          <div>{{ t('button.addCondition') }}</div>
        </div>
        <template v-if="isShowToleranceExplain">
          <div class="elv-reconciliation-match-rule-tolerance-explain__title">
            <span>{{ t('reconciliation.toleranceExplain') }}</span>
            <div class="line"></div>
          </div>

          <ExplanationReasonForm
            v-if="show"
            ref="explanationReasonFormRef"
            v-model="explanationReasonForm"
            :model="props.model"
            :adjustmentJournalTitle="$t('reconciliation.automaticallyCreateAdjustingEntries')"
            dataType="AUTOMATION"
            :currentData="props.currentRuleData"
          />
        </template>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-match-rule-dialog-footer__save"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="onClickConnect"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { $t } from '@/i18n/index'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ReconciliationApi from '@/api/ReconciliationApi'
import type { FormInstance, FormRules } from 'element-plus'
import { pick, filter, isEmpty, cloneDeep } from 'lodash-es'
import LedgerRuleSelect from './SideTable/LedgerRuleSelect.vue'
import TransferRuleSelect from './SideTable/TransferRuleSelect.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'
import ExplanationReasonForm from '../../components/ExplanationReasonForm.vue'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  entityAccountAuxiliaryTypeId: {
    type: String,
    default: ''
  },
  chartOfAccount: {
    type: Object as () => Record<string, any>,
    default: () => {
      return {}
    }
  },
  currentRuleData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const reconciliationStore = useReconciliationStore()

const emit = defineEmits(['onResetList'])
const show = defineModel('show', { type: Boolean, required: true })

const submitLoading = ref(false)
const ruleForm = ref({
  name: ''
})
const ruleFormRef = ref<FormInstance>()

const { reconciliationTaskDetail } = reactive(reconciliationStore)

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'Rule Name is required'
  },
  conditions: {
    required: true,
    trigger: 'blur',
    message: 'Conditions is required'
  }
})

const explanationReasonForm = ref({
  reconciliationReasonId: '',
  name: '',
  description: '',
  chartOfAccountId: '',
  auxiliaryValueList: [],
  memo: '',
  journalAction: 'CREATE'
})

const conditionList = ref([
  {
    index: '0',
    type: '',
    sideAField: '',
    sideBField: '',
    lowerLimit: '' as any,
    upperLimit: '' as any,
    limitUnit: '',
    auxiliaryTypeId: '',
    additionalItemPath: ''
  }
])

const ruleTypeComponentMap = {
  TRANSACTION: {
    title: t('report.transfer'),
    subTitle: t('reconciliation.perTransaction'),
    component: TransferRuleSelect
  },
  LEDGER: {
    title: t('common.accountingLedger'),
    subTitle: t('reconciliation.perLedger'),
    component: LedgerRuleSelect
  }
}

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationTaskId = computed(() => {
  return String(route.params?.reconciliationTaskId)
})

const currentRuleTypeShowInfo = computed(() => (positionType: 'LEFT' | 'RIGHT') => {
  const showInfo = { title: '', subTitle: '', component: null }
  if (positionType === 'LEFT') {
    const sideAType = reconciliationTaskDetail.dataSetTypeA as keyof typeof ruleTypeComponentMap
    return ruleTypeComponentMap[sideAType] || showInfo
  }
  if (positionType === 'RIGHT') {
    const sideBType = reconciliationTaskDetail.dataSetTypeB as keyof typeof ruleTypeComponentMap
    return ruleTypeComponentMap[sideBType] || showInfo
  }
  return showInfo
})

const isDisabledChoice = computed(() => (item: any) => {
  try {
    if (!item.sideAField || !item.sideBField) return true
    const { dataSetTypeA, dataSetTypeB } = reconciliationTaskDetail
    const disabledFieldsA = {
      TRANSACTION: ['TRANSACTION_HASH', 'ENTITY_ACCOUNT'],
      LEDGER: ['AUXILIARY', 'REFERENCE_NO', 'ADDITIONAL_ITEM']
    }
    const disabledFieldsB = {
      TRANSACTION: ['TRANSACTION_HASH', 'ENTITY_ACCOUNT'],
      LEDGER: ['AUXILIARY', 'REFERENCE_NO', 'ADDITIONAL_ITEM']
    }
    return (
      disabledFieldsA[dataSetTypeA]?.includes(item.sideAField) ||
      disabledFieldsB[dataSetTypeB]?.includes(item.sideBField)
    )
  } catch (error) {
    return false
  }
})

const toleranceOptions = reactive([
  {
    label: 'Tolerance',
    value: 'TOLERANCE'
  },
  {
    label: 'Equals',
    value: 'EQUALS'
  }
])

const isShowToleranceExplain = computed(() => {
  // conditionList中transactionField或者ledgerField有一个为AMOUNT并且type为TOLERANCE时，显示容差说明
  return conditionList.value.some(
    (item: any) => item.sideAField === 'AMOUNT' && item.sideBField === 'AMOUNT' && item.type === 'TOLERANCE'
  )
})

const saveDisabled = computed(() => {
  const isConditionList = conditionList.value.every((item: any) => {
    const { sideAField, sideBField } = item

    if (
      (sideAField === 'TRANSACTION_HASH' && sideBField === 'ADDITIONAL_ITEM') ||
      (sideBField === 'TRANSACTION_HASH' && sideAField === 'ADDITIONAL_ITEM')
    ) {
      return item.type && item.additionalItemPath && sideAField && sideBField
    }

    if (
      ((sideAField === 'DATETIME' || sideAField === 'AMOUNT') && item.type === 'TOLERANCE') ||
      ((sideBField === 'DATETIME' || sideBField === 'AMOUNT') && item.type === 'TOLERANCE')
    ) {
      const toleranceStatus =
        item.type &&
        item.lowerLimit !== '' &&
        item.lowerLimit !== undefined &&
        item.upperLimit !== '' &&
        item.upperLimit !== undefined &&
        sideAField &&
        sideBField
      if (sideAField === 'AMOUNT' || sideBField === 'AMOUNT') {
        return toleranceStatus && explanationReasonForm.value.reconciliationReasonId
      }
      return toleranceStatus
    }

    return item.type && sideAField && sideBField
  })

  return !ruleForm.value.name || !isConditionList || !conditionList.value.length
})

/**
 * @description: 交易字段改变
 * @param {object} conditionData 修改Transaction字段条件
 */
const onChangeTransactionField = (conditionData: any) => {
  const { conditionIndex, position } = conditionData
  const { dataSetTypeA, dataSetTypeB } = reconciliationTaskDetail
  const item = conditionList.value[Number(conditionIndex)]
  item.type = 'EQUALS'
  item.additionalItemPath = ''
  item.lowerLimit = undefined
  item.upperLimit = undefined
  item.auxiliaryTypeId = ''
  const resetInfo =
    position === 'LEFT'
      ? {
          sideInfo: item.sideAField,
          resetSideName: 'sideBField' as keyof typeof item,
          reconciliationType: dataSetTypeB
        }
      : {
          sideInfo: item.sideBField,
          resetSideName: 'sideAField' as keyof typeof item,
          reconciliationType: dataSetTypeA
        }
  const transactionFieldInfo = {
    LEDGER: {
      AMOUNT: 'AMOUNT',
      DATETIME: 'DATETIME',
      TRANSACTION_HASH: 'ADDITIONAL_ITEM',
      ENTITY_ACCOUNT: 'AUXILIARY'
    }
  }
  item[resetInfo.resetSideName] =
    resetInfo.reconciliationType === 'TRANSACTION'
      ? resetInfo.sideInfo
      : transactionFieldInfo[resetInfo.reconciliationType][
          resetInfo.sideInfo as keyof (typeof transactionFieldInfo)[typeof resetInfo.reconciliationType]
        ]
  if (resetInfo.reconciliationType === 'LEDGER') {
    if (resetInfo.sideInfo === 'ENTITY_ACCOUNT') {
      item.auxiliaryTypeId = props.entityAccountAuxiliaryTypeId
    }
  }
}

/**
 * @description: ledger字段改变
 * @param {object} conditionData 修改Ledger字段条件
 */
const onChangeLedgerField = (conditionData: any) => {
  const { conditionIndex, position } = conditionData
  const { dataSetTypeA, dataSetTypeB } = reconciliationTaskDetail
  const item = conditionList.value[Number(conditionIndex)]
  item.additionalItemPath = ''
  item.lowerLimit = undefined
  item.upperLimit = undefined
  item.auxiliaryTypeId = ''
  item.type = 'EQUALS'
  const resetInfo =
    position === 'LEFT'
      ? {
          sideInfo: item.sideAField,
          resetSideName: 'sideBField' as keyof typeof item,
          reconciliationType: dataSetTypeB
        }
      : {
          sideInfo: item.sideBField,
          resetSideName: 'sideAField' as keyof typeof item,
          reconciliationType: dataSetTypeA
        }

  const ledgerFieldInfo = {
    TRANSACTION: {
      AMOUNT: 'AMOUNT',
      DATETIME: 'DATETIME',
      ADDITIONAL_ITEM: 'TRANSACTION_HASH',
      AUXILIARY: 'ENTITY_ACCOUNT',
      REFERENCE_NO: 'TRANSACTION_HASH'
    }
  }
  item[resetInfo.resetSideName] =
    resetInfo.reconciliationType === 'LEDGER'
      ? resetInfo.sideInfo
      : ledgerFieldInfo[resetInfo.reconciliationType][
          resetInfo.sideInfo as keyof (typeof ledgerFieldInfo)[typeof resetInfo.reconciliationType]
        ]
  if (resetInfo.reconciliationType === 'TRANSACTION') {
    if (resetInfo.sideInfo === 'AUXILIARY') {
      item.auxiliaryTypeId = props.entityAccountAuxiliaryTypeId
    }
  }
}

const onRuleTypeSelectChange = (data: any) => {
  if (data.type === 'TRANSACTION') {
    onChangeTransactionField(data)
  }
  if (data.type === 'LEDGER') {
    onChangeLedgerField(data)
  }
}

/**
 * @description: 关闭对话框
 */
const onCloseDialog = () => {
  ruleFormRef.value?.resetFields()
  conditionList.value = [
    {
      index: '0',
      type: '',
      sideAField: '',
      sideBField: '',
      lowerLimit: '',
      upperLimit: '',
      limitUnit: '',
      auxiliaryTypeId: '',
      additionalItemPath: ''
    }
  ]
}

/**
 * @description: 保存规则
 */
const onClickConnect = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        const toleranceList = conditionList.value.map((item: any) => {
          const keys = ['sideAField', 'sideBField', 'type']
          if (item.sideAField === 'DATETIME' || item.sideAField === 'AMOUNT') {
            keys.push('limitUnit')
            if (item.type === 'TOLERANCE') {
              keys.push('lowerLimit', 'upperLimit')
            }
          }
          if (item.sideAField === 'TRANSACTION_HASH' && item.sideBField === 'ADDITIONAL_ITEM') {
            keys.push('additionalItemPath')
          }
          if (item.sideAField === 'ENTITY_ACCOUNT' && item.sideBField === 'AUXILIARY') {
            keys.push('auxiliaryTypeId')
          }
          const tolerance = pick(item, keys)
          if (tolerance?.sideAField === 'AMOUNT') {
            tolerance.limitUnit = 'NUMBER'
          }
          return tolerance
        })
        const explainData: any = { ...explanationReasonForm.value }
        const currentReason = reconciliationStore.reconciliationTaskReasonList.find(
          (item: any) => item.reconciliationReasonId === explainData.reconciliationReasonId
        )
        if (!currentReason) {
          explainData.reconciliationReasonName = explainData?.reconciliationReasonId || ''
          delete explainData?.reconciliationReasonId
        }
        delete explainData?.name
        delete explainData?.journalAction
        for (const key in explainData) {
          if (!explainData[key]) {
            delete explainData[key]
          }
        }
        const params = {
          name: ruleForm.value.name,
          toleranceList,
          ...explainData
        }
        if (props.model === 'edit') {
          await ReconciliationApi.updateReconciliationTaskRule(
            entityId.value,
            reconciliationTaskId.value,
            props.currentRuleData?.reconciliationRuleId,
            params
          )
          ElMessage.success(t('message.editSuccess'))
        } else {
          await ReconciliationApi.createReconciliationTaskRule(entityId.value, reconciliationTaskId.value, params)
          ElMessage.success(t('message.ruleSavedSuccessfully'))
        }
        emit('onResetList')
        ruleFormRef.value?.resetFields()
        show.value = false
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

/**
 * @description: 添加条件
 */
const addCondition = () => {
  if (conditionList.value.length >= 4) {
    ElMessage.error('The maximum number of conditions is 4')
    return
  }
  conditionList.value.splice(conditionList.value.length + 1, 0, {
    index: `${conditionList.value.length}`,
    type: '',
    sideAField: '',
    sideBField: '',
    lowerLimit: undefined,
    upperLimit: undefined,
    limitUnit: '',
    auxiliaryTypeId: '',
    additionalItemPath: ''
  })
}

/**
 * @description: 删除条件
 * @param {string} index
 */
const deleteCondition = (index: string) => {
  const condition = conditionList.value.find((item: any) => item.index === index)
  if (index === '0' && (condition?.sideBField === '' || condition?.sideAField === '')) return
  const newList = filter(conditionList.value, (item: any) => item.index !== index)
  newList.forEach((item: any, i: number) => {
    item.index = `${i}`
  })
  conditionList.value = newList
}

watch(
  [() => props.currentRuleData, () => show.value],
  async () => {
    if (!isEmpty(props.currentRuleData) && show.value) {
      const currentData = cloneDeep(props.currentRuleData)
      ruleForm.value.name = currentData.name
      conditionList.value = currentData.toleranceList.map((item: any, index: number) => {
        return {
          index,
          type: item.type ?? '',
          sideAField: item.sideAField ?? '',
          sideBField: item.sideBField ?? '',
          lowerLimit: item.lowerLimit || item.lowerLimit === 0 ? +item.lowerLimit : undefined,
          upperLimit: item.upperLimit || item.upperLimit === 0 ? +item.upperLimit : undefined,
          auxiliaryTypeId: item.auxiliaryTypeId ?? '',
          additionalItemPath: item.additionalItemPath ?? ''
        }
      })
      explanationReasonForm.value.journalAction = 'KEEP'
    } else if (isEmpty(props.currentRuleData) && show.value) {
      ruleForm.value.name = ''
      conditionList.value = [
        {
          index: '0',
          type: '',
          sideAField: '',
          sideBField: '',
          lowerLimit: undefined,
          upperLimit: undefined,
          limitUnit: '',
          auxiliaryTypeId: '',
          additionalItemPath: ''
        }
      ]
      explanationReasonForm.value = {
        reconciliationReasonId: '',
        name: '',
        description: '',
        chartOfAccountId: '',
        auxiliaryValueList: [],
        memo: '',
        journalAction: 'CREATE'
      }
    }
  },
  { deep: true, immediate: true }
)
</script>

<style lang="scss">
.elv-match-rule-dialog {
  width: 1200px;
  min-height: 200px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-match-rule-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 0px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-reconciliation-task-automation-dialog-content {
      width: 100%;

      .el-scrollbar__view {
        padding: 0 24px;
      }
    }

    .el-input {
      height: 44px;
      border-radius: 4px;

      &.elv-rule-form-name {
        height: 44px;
      }

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      &.elv-match-rule-form-item-line {
        .el-form-item__label {
          position: relative;
          color: #838d95;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 100%;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 704px;
            height: 1px;
            background-color: #dde1e6;
          }
        }

        &.condition .el-form-item__label::after {
          width: 1078px;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }

      .elv-rule-form-reviewed {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;

        .el-switch {
          --el-switch-on-color: #2f63eb;
          --el-switch-off-color: #dde1e6;
          width: 38px;
          height: 18px;
          border-radius: 20px;
          margin-left: 10px;
        }
      }

      .elv-rule-form-condition {
        width: 1152px;
        min-height: 52px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #ced7e0;

        .elv-match-rule-conditions:last-child {
          margin-bottom: 0px;
        }

        .elv-rule-form-condition-header {
          display: flex;
          flex-direction: column;
          background-color: #f9fafb;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;

          .elv-rule-form-condition-header-title,
          .elv-rule-form-condition-header-placeholder {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            border-bottom: 1px solid #dde1e6;

            div {
              width: 392.5px;
              height: 42px;
              display: flex;
              padding: 12px 8px;
              box-sizing: border-box;
              align-items: center;
              gap: 10px;
              border-right: 1px solid #dde1e6;
              align-self: stretch;
              color: #1e2024;
              font-family: 'Plus Jakarta Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;

              &:nth-of-type(2) {
                width: 320px;
                justify-content: center;
              }

              &:last-of-type {
                width: 45px;
                border-right: none;
              }
            }
          }

          .elv-rule-form-condition-header-placeholder div {
            height: 32px;
            color: #636b75;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }

        .elv-rule-form-condition-item {
          display: flex;
          min-height: 52px;
          box-sizing: border-box;
          border-bottom: 1px solid #dde1e6;

          &:last-of-type {
            border-bottom: none;
          }

          > div {
            display: flex;
            padding: 8px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            box-sizing: border-box;
            min-height: 52px;
            border-right: 1px solid #dde1e6;

            &:last-of-type {
              border-right: none;
            }
          }

          .elv-rule-form-condition-item-transaction,
          .elv-rule-form-condition-item-ledger {
            width: 392.5px;
          }

          .elv-rule-form-condition-item-transaction {
            .el-select {
              width: 260px;
            }
          }

          .elv-rule-form-condition-item-ledger {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .el-input,
            .el-input__wrapper {
              width: 170px;
            }
          }

          .elv-rule-form-condition-item-tolerance {
            width: 320px;

            .elv-rule-form-condition-item-tolerance__item {
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: center;

              .el-input,
              .el-input-number,
              .el-input__wrapper {
                width: 80px;
              }

              .el-select__wrapper {
                width: 216px;
              }
            }
          }

          .elv-rule-form-condition-item-delete {
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              fill: #838d95;
              cursor: pointer;

              &:hover {
                fill: #1753eb;
              }

              &.is-disabled {
                fill: #a8abb2;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    .elv-reconciliation-match-rule-tolerance-explain__title {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 10px;
      margin-top: 16px;

      span {
        color: #838d95;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        flex-shrink: 0;
      }

      .line {
        border-bottom: 1px solid #edf0f3;
        flex-grow: 1;
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }

    .elv-reconciliation-match-rule-add-condition {
      display: flex;
      align-items: center;
      margin-top: 10px;
      cursor: pointer;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      color: #1753eb;
      font-size: 13px;
      line-height: 24px;
      width: fit-content;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        fill: #1753eb;
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;

      &.is-disabled {
        background: #edf0f3;
        color: #838d95;
      }
    }
  }
}

.el-popper.elv-journal-type-drawer-category-popper {
  width: fit-content !important;
  padding: 0px;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;

  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
