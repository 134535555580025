<template>
  <div class="elv-rule-form-condition-item-transaction">
    <el-select v-model="sideDataField" v-bind="$attrs" placeholder="Select ..." @change="onChangeTransactionField">
      <el-option
        v-for="option in transactionOptions"
        :key="option.value"
        :disabled="disabledSelect(option.value)"
        :label="transformI18n(option.label)"
        :value="option.value"
      />
    </el-select>
  </div>
</template>

<script setup lang="ts">
import { isEmpty, find, findIndex } from 'lodash-es'
import { $t, transformI18n } from '@/i18n/index'
import { useReconciliationStore } from '@/stores/modules/reconciliation'

const props = defineProps<{
  conditionIndex: string | number
  conditionList: any[]
  chartOfAccount: any
  entityAccountAuxiliaryTypeId: string
  positionType: string
}>()

const emit = defineEmits(['onRuleTypeSelectChange'])

const reconciliationStore = useReconciliationStore()

const { reconciliationTaskDetail } = reactive(reconciliationStore)

const sideDataField = defineModel<any>('sideDataField', { required: true })

const transactionOptions = computed(() => {
  const list = [
    {
      label: 'Transaction ID',
      value: 'TRANSACTION_HASH'
    },
    {
      label: $t('common.amount'),
      value: 'AMOUNT'
    },
    {
      label: $t('common.date'),
      value: 'DATETIME'
    }
  ]
  if (
    !isEmpty(props.chartOfAccount) &&
    props.chartOfAccount?.auxiliaryTypeIds?.length &&
    find(props.chartOfAccount?.auxiliaryTypeIds, (i: any) => i === props.entityAccountAuxiliaryTypeId)
  ) {
    list.push({
      label: $t('common.account'),
      value: 'ENTITY_ACCOUNT'
    })
  }
  return list
})

const selectConditionList = computed(() => {
  return props.conditionList
})

const disabledSelect = computed(() => (optionItem: any) => {
  const { dataSetTypeA } = reconciliationTaskDetail
  if (dataSetTypeA === 'TRANSACTION') {
    return findIndex(selectConditionList.value, (i: any) => i.sideAField === optionItem) !== -1
  }
  return findIndex(selectConditionList.value, (i: any) => i.sideBField === optionItem) !== -1
})

const onChangeTransactionField = () => {
  emit('onRuleTypeSelectChange', {
    type: 'TRANSACTION',
    position: props.positionType,
    conditionIndex: props.conditionIndex
  })
}
</script>

<style lang="scss" scoped></style>
