<template>
  <div class="elv-rule-form-condition-item-ledger">
    <el-select
      v-model="sideDataField"
      placeholder="Select ..."
      :style="{ width: sideDataField === 'ADDITIONAL_ITEM' ? '170px' : '260px' }"
      @change="onChangeLedgerField"
    >
      <el-option
        v-for="option in ledgerOptions"
        :key="option.value"
        :disabled="isDisabledSelected(option.value)"
        :label="transformI18n(option.label)"
        :value="option.value"
      />
    </el-select>
    <el-input v-if="sideDataField === 'ADDITIONAL_ITEM'" v-model="additionalItemPath" placeholder="Path" />
  </div>
</template>

<script setup lang="ts">
import { $t, transformI18n } from '@/i18n/index'
import { find, findIndex, isEmpty } from 'lodash-es'
import { useReconciliationStore } from '@/stores/modules/reconciliation'

const props = defineProps<{
  conditionIndex: string | number
  conditionList: any[]
  chartOfAccount: any
  entityAccountAuxiliaryTypeId: string
  positionType: string
}>()

const emit = defineEmits(['onRuleTypeSelectChange'])

const reconciliationStore = useReconciliationStore()
const sideDataField = defineModel<any>('sideDataField', { required: true })
const additionalItemPath = defineModel<any>('additionalItemPath', { required: true })

const { reconciliationTaskDetail } = reactive(reconciliationStore)

const ledgerOptions = computed(() => {
  const list = [
    {
      label: $t('common.amount'),
      value: 'AMOUNT'
    },
    {
      label: $t('common.date'),
      value: 'DATETIME'
    },
    {
      label: $t('title.additionalItem'),
      value: 'ADDITIONAL_ITEM'
    },
    {
      label: 'Reference No.',
      value: 'REFERENCE_NO'
    }
  ]
  if (
    !isEmpty(props.chartOfAccount) &&
    props.chartOfAccount?.auxiliaryTypeIds?.length &&
    find(props.chartOfAccount?.auxiliaryTypeIds, (i: any) => i === props.entityAccountAuxiliaryTypeId)
  ) {
    list.push({
      label: 'Auxiliary.Account',
      value: 'AUXILIARY'
    })
  }
  return list
})

const selectConditionList = computed(() => {
  return props.conditionList
})

const isDisabledSelected = computed(() => (currentOption: string) => {
  const { dataSetTypeA, dataSetTypeB } = reconciliationTaskDetail
  const isLeftPosition = props.positionType === 'LEFT'
  const isRightPosition = props.positionType === 'RIGHT'
  const optionIndex = isLeftPosition
    ? findIndex(selectConditionList.value, (i: any) => i.sideAField === currentOption)
    : findIndex(selectConditionList.value, (i: any) => i.sideBField === currentOption)

  if (optionIndex !== -1) {
    return true
  }
  if ((isLeftPosition && dataSetTypeB === 'TRANSACTION') || (isRightPosition && dataSetTypeA === 'TRANSACTION')) {
    if (['REFERENCE_NO', 'ADDITIONAL_ITEM'].includes(currentOption)) {
      const transactionHashIndex = isLeftPosition
        ? findIndex(selectConditionList.value, (i: any) => i.sideBField === 'TRANSACTION_HASH')
        : findIndex(selectConditionList.value, (i: any) => i.sideAField === 'TRANSACTION_HASH')
      if (transactionHashIndex !== -1 && transactionHashIndex !== Number(props.conditionIndex)) {
        return true
      }
    }
  }
  return false
})

const onChangeLedgerField = () => {
  emit('onRuleTypeSelectChange', {
    type: 'LEDGER',
    position: props.positionType,
    conditionIndex: props.conditionIndex
  })
}
</script>

<style lang="scss" scoped></style>
