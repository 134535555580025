<template>
  <el-table
    ref="tableElRef"
    v-loading="props.loading"
    :data="listData"
    style="width: 100%"
    :min-height="230"
    border
    stripe
    :row-class-name="disableRow"
    class="elv-reconciliation-match-rule-table"
  >
    <el-table-column :label="t('common.priority')" width="85" class-name="none-padding">
      <template #default="{ row }">
        <PriorityCell :row="row" />
      </template>
    </el-table-column>
    <el-table-column prop="name" :label="t('title.matchSetName')" width="200">
      <template #default="{ row }">
        <span class="elv-reconciliation-match-rule-table-row__cell-name">{{ formatLanguageContent(row.name) }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="t('common.conditions')" min-width="355">
      <template #default="{ row }">
        <ConditionCell :data="row" />
      </template>
    </el-table-column>
    <el-table-column :label="t('report.matches')" width="120" align="right" header-align="right">
      <template #default="{ row }">
        <span class="elv-reconciliation-match-rule-table-row__cell-matches" @click="setMatchesFilterRuleInfo(row)">{{
          formatNumber(row.matchCount ?? 0)
        }}</span>
      </template>
    </el-table-column>
    <el-table-column :resizable="false" width="120">
      <template #default="{ row, $index }">
        <div v-if="$index !== 0" class="elv-reconciliation-match-rule-table-row__cell-button">
          <SvgIcon name="sources-edit" width="18" height="18" @click="onEditRule(row)" />
          <SvgIcon name="sources-delete" width="18" height="18" @click="onOpenDeleteConfirm(row)" />
        </div>
      </template>
    </el-table-column>
  </el-table>

  <ElvMessageBox
    ref="deleteMessageBoxRef"
    :showCancelButton="true"
    :confirmButtonText="t('button.delete')"
    :cancelButtonText="t('button.cancel')"
    :title="t('report.deleteRule')"
    :loading="deleteLoading"
    @onConfirmEvent="onConfirmDeleteMatchRule"
    @onCancelEvent="deleteMessageBoxRef?.onCheckMessageBoxDialog()"
  >
    <template #content>
      <span class="elv-confirm-counterparty">{{ t('message.deleteMatchRuleInfo') }}</span></template
    >
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import PriorityCell from './Cell/PriorityCell.vue'
import ConditionCell from './Cell/ConditionCell.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import { formatNumber, formatLanguageContent } from '@/lib/utils'
import { ReconciliationTaskRuleItemType } from '#/ReconciliationTypes'
import { useReconciliationStore } from '@/stores/modules/reconciliation'

const props = defineProps({
  ruleParams: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const deleteLoading = ref(false)

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reconciliationStore = useReconciliationStore()

const tableElRef = useTemplateRef('tableElRef')
const deleteMessageBoxRef = useTemplateRef('deleteMessageBoxRef')
const currentRule = ref<ReconciliationTaskRuleItemType>({} as ReconciliationTaskRuleItemType)

const entityId = computed(() => {
  return route.params?.entityId as string
})

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const emit = defineEmits(['onEditRule', 'onChangeStatus', 'onChangePage', 'onChangePageSize', 'onResetList'])

const listData = computed(() => {
  return reconciliationStore.reconciliationTaskRuleList?.list
})

/**
 * @description: 禁用规则（无法排序、删除、编辑）
 * @param {*} data
 * @return {*}
 */
const disableRow = (data: any) => {
  if (!data.row.isEnabled) return 'elv-rule-table-is-disabled'
  return ''
}

/**
 * @description: 编辑匹配规则
 * @param {ReconciliationTaskRuleItemType} row
 */
const onEditRule = (row: ReconciliationTaskRuleItemType) => {
  emit('onEditRule', row)
}

/**
 * @description: 删除匹配规则
 */
const onConfirmDeleteMatchRule = async () => {
  try {
    deleteLoading.value = true
    await ReconciliationApi.deleteReconciliationTaskRule(
      entityId.value,
      reconciliationTaskId.value,
      currentRule.value?.reconciliationRuleId
    )
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('message.deleteSuccess'))
    emit('onResetList')
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

/**
 * @description: 打开删除确认框
 * @param {ReconciliationTaskRuleItemType} row
 */
const onOpenDeleteConfirm = (row: ReconciliationTaskRuleItemType) => {
  currentRule.value = row
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 跳转已匹配列表设置筛选规则参数
 * @param {ReconciliationTaskRuleItemType} row
 */
const setMatchesFilterRuleInfo = (row: ReconciliationTaskRuleItemType) => {
  const filterSaveData = reconciliationStore.reconciliationFilterTypeInfo?.find((item: any) => {
    return item.type === 'MATCHED'
  })
  if (filterSaveData) {
    const currentEntityFilterInfo = filterSaveData?.list?.find((item: any) => item.entityId === entityId.value)
    if (currentEntityFilterInfo && currentEntityFilterInfo?.data) {
      if (currentEntityFilterInfo.data?.userId) {
        delete currentEntityFilterInfo.data?.userId
        currentEntityFilterInfo.data.reconciliationRuleId = row?.reconciliationRuleId || ''
      } else {
        if (currentEntityFilterInfo.data?.reconciliationRuleId) {
          currentEntityFilterInfo.total += 1
        }
        currentEntityFilterInfo.data.reconciliationRuleId = row?.reconciliationRuleId || ''
      }
    } else {
      reconciliationStore.editReconciliationFilter(entityId.value, {
        reconciliationRuleId: row?.reconciliationRuleId || ''
      })
    }
  } else {
    reconciliationStore.editReconciliationFilter(entityId.value, {
      reconciliationRuleId: row?.reconciliationRuleId || ''
    })
  }
  router.push({
    name: 'entity-reconciliation-matches'
  })
}

onBeforeUnmount(() => {
  currentRule.value = {} as ReconciliationTaskRuleItemType
})

defineExpose({
  tableElRef
})
</script>

<style lang="scss">
.elv-reconciliation-match-rule-table.el-table {
  --el-table-border-color: #ced7e0;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #d4dce6;

  .el-table__header {
    .el-table__cell {
      height: 36px;
      padding: 0;
      background: #eef4fb;
      border-bottom-color: #ced7e0;
      border-right-color: #e4e7eb;

      &:last-of-type {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        padding: 0 10px;
        height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
      }
    }
  }

  &.el-table--border {
    .el-table__inner-wrapper {
      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__border-left-patch {
    display: none;
  }

  .el-switch {
    --el-switch-on-color: #2f63eb;
    --el-switch-off-color: #dde1e6;
    width: 38px;
    height: 18px;
    border-radius: 20px;
  }

  .el-table__row {
    &:not(.elv-rule-table-is-disabled) {
      &:hover {
        background: #f5f7fa;
      }
    }

    &:last-of-type .el-table__cell {
      border-bottom: 0px;
    }

    &.elv-rule-table-is-disabled {
      background: #f9fafb;
      opacity: 0.6;
    }

    .el-table__cell {
      padding: 0;

      &:last-of-type {
        border-right: 0px;
      }

      &.is-right .cell {
        text-align: right;
        justify-content: flex-end;
      }

      &.is-center .cell {
        text-align: center;
        justify-content: center;
      }

      &.is-number .cell {
        font-family: 'Barlow';
        font-weight: 500;
      }

      .cell {
        padding: 0 10px;
        min-height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
        word-break: break-word;

        .elv-reconciliation-match-rule-table-row__cell-action__title {
          color: #000;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 4px;
        }

        .elv-reconciliation-match-rule-table-row__cell-action__label {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          white-space: nowrap;
        }

        .elv-reconciliation-match-rule-table-cell-conditions__item-is {
          margin: 0 6px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          color: #636b75;

          span:nth-child(2) {
            margin-left: 4px;
          }
        }

        .elv-reconciliation-match-rule-table-cell-conditions__item-value {
          white-space: nowrap;
        }

        .elv-reconciliation-match-rule-table-row__cell-action__value {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-left: 4px;
          white-space: nowrap;
        }
      }

      &.none-padding {
        .cell {
          padding: 0px;
        }
      }
    }
  }

  .elv-reconciliation-match-rule-table-row__cell-name {
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-reconciliation-match-rule-table-row__cell-matches {
    cursor: pointer;
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }

  .elv-reconciliation-match-rule-table-row__cell-data {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-reconciliation-match-rule-table-row__cell-action {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-reconciliation-match-rule-table-cell-action__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .elv-reconciliation-match-rule-table-row__cell-conditions {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-reconciliation-match-rule-table-cell-conditions__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .elv-reconciliation-match-rule-table-cell-conditions__item-type {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #0e0f11;
    }
  }

  .elv-reconciliation-match-rule-table-row__cell-actions {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #0e0f11;

    p {
      display: flex;
      align-items: center;
    }

    span {
      height: 18px;
      box-sizing: border-box;
      padding: 0 6px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      margin-left: 6px;
      color: #ffffff;
    }
  }

  .elv-reconciliation-match-rule-table-row__cell-switch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elv-reconciliation-match-rule-table-row__cell-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;
      fill: #838d95;

      &.is-disabled {
        cursor: not-allowed !important;
        fill: #edf0f3 !important;
      }

      &:first-of-type {
        margin-right: 8px;
      }

      &:hover {
        fill: #1e2024;
      }
    }
  }
}
</style>
