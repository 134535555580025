<template>
  <div class="elv-reconciliation-task-automation-container">
    <section class="elv-reconciliation-task-automation-content">
      <header class="elv-reconciliation-task-automation-header">
        <div class="elv-reconciliation-task-automation-header-info">
          <h5>{{ t('reconciliation.automation') }}</h5>
          <p>{{ t('reconciliation.automationTitleInfo') }}</p>
        </div>
        <div class="elv-reconciliation-task-automation-header-operation">
          <elv-button height="32" width="94" round plain type="primary" @click="onAddRule">
            <SvgIcon name="source-add" width="16" height="16" />{{ t('button.addRule') }}</elv-button
          >
          <elv-button
            type="primary"
            round
            width="160"
            height="32"
            :loading="matchingLoading"
            :disabled="reconciliationTaskDetail?.status !== 'NORMAL'"
            @click="onStartMatching"
          >
            <img
              v-if="reconciliationTaskDetail.status === 'MATCHING'"
              class="loading-animation"
              src="@/assets/img/reports/sources-sync-loading.png"
              alt="loading"
            />
            <SvgIcon v-else name="play--filled" width="14" height="14" class="icon" fill="#fff" />{{
              t('reconciliation.startAutoMatching')
            }}</elv-button
          >
        </div>
      </header>

      <div class="elv-reconciliation-task-automation-table-container">
        <RuleTable
          ref="ruleTableRef"
          :ruleParams="ruleParams"
          :loading="loading"
          @onEditRule="onEditRule"
          @onChangePage="onChangePage"
          @onChangePageSize="onChangePageSize"
          @onChangeStatus="onChangeStatus"
          @onResetList="getReconciliationTaskRuleList"
        />
      </div>

      <RuleDialog
        v-model:show="showRuleDialog"
        :model="ruleDialogModel"
        :chartOfAccount="chartOfAccount"
        :currentRuleData="currentRuleData"
        :entityAccountAuxiliaryTypeId="entityAccountAuxiliaryTypeId"
        @onResetList="getReconciliationTaskRuleList"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import Sortable from 'sortablejs'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import RuleTable from './components/RuleTable.vue'
import RuleDialog from './components/RuleDialog.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import { useEntityStore } from '@/stores/modules/entity'
import { ReconciliationTaskRuleItemType } from '#/ReconciliationTypes'
import { useReconciliationStore } from '@/stores/modules/reconciliation'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const reconciliationStore = useReconciliationStore()
const { reconciliationTaskDetail, reconciliationTaskDataSetList } = storeToRefs(reconciliationStore)

const loading = ref(false)
const showRuleDialog = ref(false)
const matchingLoading = ref(false)
const ruleDialogModel = ref('add')
const entityAccountAuxiliaryTypeId = ref('')
const ruleTableRef = useTemplateRef('ruleTableRef')
const currentRuleData = ref<ReconciliationTaskRuleItemType>({} as ReconciliationTaskRuleItemType)

const ruleParams = ref({
  page: 1,
  limit: 20
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const chartOfAccount = computed(() => {
  if (!reconciliationTaskDataSetList.value?.sideBList?.length) return {}
  return (
    find(reconciliationTaskDataSetList.value?.sideBList?.[0]?.conditions || [], { type: 'CHART_OF_ACCOUNT' })?.value ||
    {}
  )
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

/**
 * @description: 新增对账规则
 */
const onAddRule = () => {
  ruleDialogModel.value = 'add'
  currentRuleData.value = {} as ReconciliationTaskRuleItemType
  showRuleDialog.value = true
}

/**
 * @description: 编辑对账规则
 * @param {ReconciliationTaskRuleItemType} row
 */
const onEditRule = (row: ReconciliationTaskRuleItemType) => {
  ruleDialogModel.value = 'edit'
  currentRuleData.value = row
  showRuleDialog.value = true
}

// eslint-disable-next-line no-unused-vars
const onChangeStatus = async (row: any, status: any, index: number) => {
  try {
    loading.value = true
    if (status) {
      ElMessage.success(t('message.ruleEnabled'))
    } else {
      ElMessage.success(t('message.ruleDisabled'))
    }
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

/**
 * @description: 重置对账任务详情
 */
const onResetDetail = async () => {
  try {
    reconciliationStore.detailLoading = true
    reconciliationStore.fetchReconciliationTaskDetail(entityId.value, reconciliationTaskId.value)
  } catch (error: any) {
    console.log(error)
  } finally {
    reconciliationStore.detailLoading = false
  }
}

/**
 * @description: 获取对账规则列表
 */
const getReconciliationTaskRuleList = async () => {
  try {
    loading.value = true
    await reconciliationStore.fetchReconciliationTaskRuleList(
      entityId.value,
      reconciliationTaskId.value,
      ruleParams.value
    )
  } catch (error: any) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

/**
 * @description: 修改每页显示条数
 * @param {number} limit
 */
const onChangePageSize = (limit: number) => {
  ruleParams.value.limit = limit
  ruleParams.value.page = 1
  getReconciliationTaskRuleList()
}

/**
 * @description: 修改当前页数
 * @param {number} page
 */
const onChangePage = (page: number) => {
  ruleParams.value.page = page
  getReconciliationTaskRuleList()
}

/**
 * @description: 开始执行自动对账
 */
const onStartMatching = async () => {
  try {
    matchingLoading.value = true
    await ReconciliationApi.executeReconciliationTaskRule(entityId.value, reconciliationTaskId.value)
    ElMessage.success(t('message.success'))
    onResetDetail()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    matchingLoading.value = false
  }
}

/**
 * @description: 初始化拖拽排序
 */
const initSortable = () => {
  nextTick(() => {
    const table = ruleTableRef.value?.tableElRef?.$el.querySelector('.el-table__body-wrapper tbody')
    let originalOrder: any
    Sortable.create(table, {
      animation: 300,
      handle: '.elv-reconciliation-matchSet-detail-rule-draggable__icon',
      onStart: () => {
        if (
          ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
          !currentEntityPermission.value?.automationRule?.update
        ) {
          ElMessage.warning(t('message.noPermission'))
          return
        }
        originalOrder = Array.from(table.children)
      },
      filter: '.elv-reconciliation-matchSet-detail-rule-draggable .is-disabled',
      onMove: (evt) => {
        // 禁止跨组拖拽
        const draggedElement = evt.dragged
        const relatedElement = evt.related

        // 判断是否包含 is-disabled class 的子元素
        const isDraggedElementIconPresent = !!draggedElement.querySelector(
          '.elv-reconciliation-matchSet-detail-rule-draggable .elv-reconciliation-matchSet-detail-rule-draggable__icon'
        )
        const isRelatedElementNotDisabled = !relatedElement.querySelector(
          '.elv-reconciliation-matchSet-detail-rule-draggable .is-disabled'
        )
        return isDraggedElementIconPresent && isRelatedElementNotDisabled
      },
      onEnd: async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          try {
            let newList: any = []
            loading.value = true
            newList = reconciliationStore.reconciliationTaskRuleList.list.slice()
            newList.splice(Number(newIndex), 0, newList.splice(Number(oldIndex), 1)[0])
            await ReconciliationApi.editAutomationRulePriority(entityId.value, reconciliationTaskId.value, {
              reconciliationRuleIds:
                newList
                  .filter((item: any) => item?.reconciliationRuleId !== '0')
                  .map((item: any) => item?.reconciliationRuleId) || []
            })
            ElMessage.success(t('message.priorityChanged'))
            reconciliationStore.reconciliationTaskRuleList.list = []
            await getReconciliationTaskRuleList()
          } catch (error: any) {
            loading.value = false
            table.innerHTML = '' // 清空表格内容
            for (const row of originalOrder) {
              table.appendChild(row)
            }
            ElMessage.error(error.message)
          }
        }
      }
    })
  })
}

watch(
  () => route,
  async () => {
    if (route.name === 'entity-reconciliation-automation') {
      try {
        const entityAccountAuxiliaryType = new Promise((resolve, reject) => {
          LedgerApi.getAuxiliaryTypeList(entityId.value, {
            page: 1,
            limit: 1
          })
            .then((res) => {
              entityAccountAuxiliaryTypeId.value = res.data.list[0].auxiliaryTypeId
              return resolve(res)
            })
            .catch((e) => {
              return reject(e)
            })
        })
        await Promise.allSettled([entityAccountAuxiliaryType, getReconciliationTaskRuleList()])
        nextTick(() => {
          initSortable()
        })
      } catch (error: any) {
        console.log(error)
      }
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  reconciliationStore.reconciliationTaskRuleList = {
    list: [],
    totalCount: 0
  }
})
</script>

<style lang="scss" scoped>
.elv-reconciliation-task-automation-container {
  width: 100%;
  height: calc(100% - 130px);
  position: relative;
  display: flex;
  flex-direction: column;
}

.elv-reconciliation-task-automation-content {
  flex: 1;
  min-height: 0;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 30px;
  position: relative;
}

.elv-reconciliation-task-automation-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 16px;

  .elv-reconciliation-task-automation-header-info {
    font-family: 'Plus Jakarta Sans';

    h5 {
      color: #0e0f11;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 4px;
    }

    p {
      color: #838d95;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .elv-reconciliation-task-automation-header-operation {
    display: flex;
    align-items: center;

    .elv-button + .elv-button {
      margin-left: 16px;
      font-size: 12px;
      font-weight: 500;

      .loading-animation {
        width: 14px;
        height: 14px;
        margin-right: 10px;
        animation: loading-rotate 2s linear infinite;
      }

      &.is-disabled {
        svg {
          fill: #838d95;
        }
      }
    }
  }
}

.elv-reconciliation-task-automation-table-container {
  width: 100%;
  height: calc(100% - 74px);
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
